import React from 'react';

import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';
import ContactForm from '../components/Forms/ContactForm';
import PageBanner from '../components/page-banner';

const ContactPage = () => (
  <Layout>
    <Header headerType="" />
    <SEO title="Contact" />
    <PageBanner />
    <div className="container contact-us">
      <div className="row">
        <div className="md:col-8 col-12">
          <div className="breadcrumbs">
            <ul>
              <li>
                <AniLink fade className="home" to="/">
                  Home
                  <span>/</span>
                </AniLink>
              </li>
              <li>
                <AniLink fade className="current" to="/contact">
                  Contact
                </AniLink>
              </li>
            </ul>
          </div>
          <h1>Contact us</h1>
          <p>
            Certified as Site Safe, Otago Painting Solutions are the qualified,
            Master Painters team to work on any commercial decorating project
            across Otago, small or large. With over 30 years experience, Otago
            Painting Solutions have a proud history in Dunedin and throughout
            Otago. Our expert team can handle work of any size.
          </p>
          <p>
            Expert attention to detail has earned us a solid reputation with
            many testimonials to prove it, from the smallest of home painting
            and decorating to the largest commercial jobs in town.
          </p>
        </div>
      </div>
    </div>
    <ContactForm heading="Leave us a message" subheading="" theme="blue" map />
  </Layout>
);

export default ContactPage;
